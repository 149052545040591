<template>
  <div>
    <van-popup
      v-model="show"
      :safe-area-inset-bottom="true"
      :overlay="showCover"
      @close="closePopup"
      position="bottom"
      :close-on-click-overlay="false"
      :lock-scroll="true"
      :closeable="!showCoupon"
    >
      <div class="content" v-show="!showCoupon">
        <div class="title">{{ payData.boxTitle? payData.boxTitle: '确认订单'}}</div>
        <div class="name row-between-center">
          <div class="left">商品名称</div>
          <div class="left fw6">{{ payData.subject? payData.subject: ''}}</div>
        </div>
        <div v-if="payData.goodsNum" class="goodsNum row-between-center">
          <div class="left">数量</div>
          <div class="left fw6">{{ payData.goodsNum}}件</div>
        </div>
        <div class="price row-between-center">
          <div class="left">价格</div>
          <div><span class="s1" v-if="payData.originalPriceStr">¥{{ payData.originalPriceStr }}</span><span class="s2">¥</span><span class="s3">{{ payData.totalAmount }}</span></div>
        </div>
        <div v-if="selectedCouponId" @click="selectCoupon()" class="coupon row-between-center">
          <div class="left">优惠</div>
          <div class="left fw6">-¥{{ selectedDiscount }}</div>
        </div>
        <div class="pay-type">选择支付方式</div>
        <div class="wechat row-between-center" :class="{'active': payType === 1}">
          <div class="left"><img
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png" alt="">
            微信支付
          </div>
        </div>
        <!--支付宝支付 占位-->
        <div class="alipay row-between-center" style="background: none" :class="{'active': payType === 2}">
          <!--<div class="left"><img-->
          <!--src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png" alt="">支付宝支付-->
          <!--</div>-->
        </div>
        <div @click="miniPay()" class="pay-btn row-center-center fw6">￥{{ submitMoney }} 去支付</div>
      </div>

      <div class="coupon-box content" v-show="showCoupon">
        <div @click="backPay()" class="coupon-title">选择优惠</div>
        <div class="coupon-list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div @click="selectedC(item)" class="item-i" :class="{'active': item.active}">
            <div class="item-l col-center-center">
              <div><span>¥</span>{{ item.discount }}</div>
              <div>{{ item.fullReduction }}</div>
            </div>
            <div class="item-r">
              <img class="arrow" @click.stop="openTips(item)" :class="{'rotate': item.active}"
                   src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-down.png"
                   alt="">
              <div class="coupon-name">{{ item.cashVoucherUserName }}</div>
              <div class="coupon-tabs">
                <span v-for="v in item.typeList" :key="v">{{ v }}</span>
              </div>
              <div class="coupon-date">{{ item.efficientDate }}</div>
            </div>
          </div>
          <div class="instructions" :class="{'active': item.active, 'close': item.active !== undefined && !item.active}"><p>
            {{item.instructions}}
          </p></div>
        </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {mapState } from 'vuex'
import wx from "weixin-js-sdk";
export default {
  props: {
    showCover: {
      type: Boolean,
      default: true
    },
    cityId: {
      default: '3'
    }
  },
  data() {
    return {
      show: false,
      payType: 1, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      showCoupon: false,
      // 鹿充券列表
      list: [{active: false}, {active: false}, {active: false}, {active: false}, {active: false}],
      // 支付参数
      selectedDiscount: 0,
      selectedCouponId: '',
      // submitMoney: 0 // 最终支付金额
    };
  },
  computed:{
    ...mapState (['showPayPopup', 'payData', 'payUrl']),
    submitMoney() {
      return (this.payData.totalAmount - this.selectedDiscount) > 0 ? (this.payData.totalAmount - this.selectedDiscount) : '0.00'
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('setShowPayPopup', false)
      this.$store.commit('setPayData', {})
      this.$store.commit('setPayUrl', '')
      this.showCoupon = false
    },
    selectCoupon() {
      this.showCoupon = true
    },
    getCashVoucher() {
      this.$axios.post( this.baseURLApp + '/cashVoucher/user/listUserUseable',{
        goodsId: this.payData.mouldId,
        payableAmount: this.payData.totalAmount,
        token: this.payData.token,
        userId: this.payData.userId,
        type: this.payData.type,
        cityId: this.cityId
      }).then(res => {
        console.log(res)
        this.list = res.data ?? []
        this.selectedDiscount = this.list?.length > 0 ? this.list[0].discount : 0
        this.selectedCouponId = (this.list?.length > 0 ? this.list[0].cashVoucherUserId : '')
      })
    },
    selectedC(v){
      this.selectedDiscount = v.discount
      this.selectedCouponId = v.cashVoucherUserId
      this.list.map(item => {
        item.active = undefined
        return
      })
      this.showCoupon = false
    },
    openTips(v){
      v.active = !v.active
      this.$forceUpdate()
    },
    miniPay(){
      let payParam = JSON.parse(JSON.stringify(this.payData)), url = this.payUrl
      payParam.wxPayType = 'applet'
      payParam.totalAmount = this.submitMoney
      payParam.discountId = this.selectedCouponId
      let p = JSON.stringify(payParam)
      let redirectUri = encodeURIComponent(url)
      console.log(redirectUri);
      wx.miniProgram.redirectTo({
        url: '/pages/webViewPay/index?redirect_uri='+ redirectUri +'&payData='+ p,
        success: function(re) {
          console.log('跳转成功');
        },
        fail: function(r) {
          console.log('跳转失败');
        }
      })
    },
    // 返回选择券
    backPay() {
      this.list.map(item => {
        item.active = undefined
        return
      })
      this.showCoupon = false
    }
  },
  watch: {
    showPayPopup(val) { // 显示隐藏
      console.log(this.payData)
      this.show = val
      if (val) {
        // 29组合购买 不支持
        this.payData.type == 29 ? null : this.getCashVoucher()
      }else{
        this.closePopup()
      }
    },

  },
};
</script>
<style lang="less" scoped>
.instructions{
  //height: 0;
  width: 100%;
  overflow: hidden;
  max-height:0;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1) inset;
  box-sizing: border-box;
}
.instructions p{
  padding: 24px;
  font-size: 20px;
  color: #9AA1A9;
  line-height: 32px;
}
.instructions.active{
  animation: open .5s forwards;
}
.instructions.close{
  animation: close .5s forwards;
}
@keyframes open {
  0%{
    max-height:0;
  }
  100%{
    max-height: 120px;
    overflow-y: scroll;
  }
}
@keyframes close {
  0%{
    max-height: 120px;
  }
  100%{
    max-height:0;
    overflow-y: hidden;
  }
}
.content {
  padding: 0 32px 32px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.coupon-box {
  background: #F5F5F5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #FFDE00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}

.coupon {
  height: 100px;
  box-sizing: border-box;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-right.png") no-repeat center right;
  background-size: 24px 24px;
  padding-right: 34px;
}
.coupon-title{
  font-weight: bold;
  color: #242831;
  font-size: 36px;
  padding-left: 56px;
  height: 144px;
  display: flex;
  align-items: center;
  background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-left.png") no-repeat center left;
  background-size: 40px 40px;
}
.coupon-list{
  height: calc(100% - 144px);
  overflow-y: scroll;
}
.coupon-list::-webkit-scrollbar{
  display: none;
}
.price {
  padding: 28px 0 32px;
}

.price > div .s1 {
  text-decoration: line-through;
  color: #6C727A;
  line-height: 28px;
  font-size: 24px;
  margin-right: 16px;
}

.price > div .s2 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 24px;
  font-weight: bold;
}

.price > div .s3 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 40px;
  font-weight: bold;
}

.left {
  font-size: 24px;
  color: #242831;
  line-height: 28px;
}

.title {
  color: #242831;
  font-size: 36px;
  font-weight: bold;
  padding: 48px 0;
}

.pay-type {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 8px;
}

.wechat, .alipay {
  background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/normal.png") no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/active.png");
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}
.coupon-box .item {
  background-color: white;
}

.coupon-box .item {
  margin-bottom: 32px;
}

.coupon-box .item-i {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}
.coupon-box .item-l {
  width: 192px;
  height: 200px;
  background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/card-coupon-active.png");
  background-size: 100% 100%;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
}
.coupon-box .item-l > div:first-child {
  font-family: BebasNeueBold;
  font-size: 80px;
}
.coupon-box .item-l> div:first-child span{
  font-size: 36px;
}
.coupon-box .item-r {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 16px;
  position: relative;
}

.coupon-box .item-r .coupon-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;


}
.coupon-box .item-r .coupon-tabs span {
  padding: 4px 8px;
  font-weight: bold;
  color: #FB732E;
  font-size: 18px;
  border: 1px solid #FB732E;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.coupon-box .item-r .coupon-date {
  margin-top: 38px;
  font-size: 20px;
  line-height: 23px;
  color: #6C727A;
  position: absolute;
  left: 16px;
  bottom: 24px;

}

.coupon-box .item-r .coupon-name {
  line-height: 28px;
  font-weight: bold;
  font-size: 24px;
  padding-top: 24px;
}
.goodsNum{
  margin-top: 28px;
}
.coupon-box .item-r .arrow {
  position: absolute;
  right: 4px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  transition: transform .5s;
  padding: 20px;
}

.coupon-box .arrow.rotate {
  transform: rotateZ(180deg);
}


</style>

