import axios from "axios";
import Vue from 'vue'
import { Toast } from "vant";
import appConfig from "./appConfig"
import store from '@/store'
// import { loginOut } from "../lib/appMethod"
// axios.defaults.baseURL = staticConfig.baseUrl;
axios.defaults.timeout = 30000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.put["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.version = appConfig.version;
axios.defaults.headers.appVersion = appConfig.appVersion;
axios.defaults.headers.userId = store.state.userId || ''
axios.defaults.headers.token = store.state.token || ''

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "操作太频繁，请稍后重试";
          break;
        case 404:
          error.message = "请求错误,未找到该资源"+error.response.config.url;
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      error.message = "网络出现问题,请稍后再试";
    }
    if(error.message == "网络出现问题,请稍后再试"){
      return Promise.reject(error);
    } else{
      Toast({
        message: error.message,
        position: "middle",
        forbidClick: true,
        duration: 2000
      });
      return Promise.reject(error);
    }
  }
);

// 公用请求
function commonAjax(methodType,url, param = {}, toastErr){
  return new Promise((resolve, reject) => {
    if(param.userId) {
      axios.defaults.headers.userId = param.userId
      axios.defaults.headers.authorization = store.state.token || param.token || ''
      axios.defaults.headers.token = store.state.token || param.token || ''
    }
    if(param.authorization) {
      axios.defaults.headers.authorization = param.authorization;
    }
    if(param.venueId) {
      axios.defaults.headers.venueId = param.venueId;
    }
    axios({
      // headers: {
      //   "version": 1,
      //   "Content-Type": "application/json;charset=utf-8",
      // },
      method: methodType,
      url: url.indexOf('http') > -1 ? url : `${Vue.prototype.baseURLApp}${url}`,
      data: param
    }).then(res => {
      switch(""+res.data.code){
        case "1":
          // 成功返回
          resolve(res.data);
          break;
        case "2":
        // case 2:
        // case 1010002:
        // case '1010002':
        // case 10100024:
        // case '10100024':
          // token 失效，回到登录页面
          // loginOut();
          // 20230523修改 未登入弹窗
          Toast.clear()
          store.commit('openLoginPopup', true)
          reject(res.data);
          break;
        default:
          // 系统级错误，直接提示错误
          if(res.data.msg && toastErr){
            Toast({
              message: res.data.msg,
              forbidClick: true,
              position: "middle",
              duration: 2000
            });
          }
          reject(res.data);
          break;
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export default {
  //get请求
  get(url,param) {
    return commonAjax("get",url,param)
  },
  //post请求 toastErr 是否toast错误提示 默认展示
  post(url, param, toastErr = true) {
    return commonAjax("post",url,param, toastErr)
  }
};
